<template>


  <div class="pa-10">

    <title-page icon="ico-carplug">
      {{ $t('rfid.search-a-rfid') }}
    </title-page>

    <tokens-manager />

  </div>
</template>

<script>
import RfidFormModal from "@modals/RfidFormModal.vue";
import RulesMixin from "@mixins/RulesMixin";
import TokenRepository from "@repository/TokenRepository";
import tokenRepository from "@repository/TokenRepository";
import TokensManager from "@/components/elements/TokensManager.vue";

export default {

  components: {
    TokensManager,
  },

  data() {
    return {

    }
  },

  props: {

  },

  mixins: [

  ],

  mounted() {

  },

  watch: {

  },

  computed: {




  },

  methods: {

  }
}
</script>

<style lang="scss">


</style>