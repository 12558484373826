<template>
  <div class="subscriptions-search pa-10">

    <title-page icon="mdi-file-chart-outline">
      {{ $t('subscription.manage-subscription-plans') }}
    </title-page>

    <v-row class="mt-10">
      <v-col>
        <v-row v-if="currentUser.permissions.includes('OPERATOR_WRITE')" class="mb-3">
          <v-col class="text-right">
            <v-btn small :to="{name: 'subscription-plans.add'}">
              <v-icon left>mdi-plus</v-icon>
              {{ $t('subscription.add-plan')}}
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="subscriptionPlans"
            :options.sync="options"
        >

          <template #item.name="{item, value}">
            <router-link :to="{name: 'subscription-plans.edit', params: {id: item.id}}">
              {{ value }}
            </router-link>
          </template>

          <template #item.enabled="{value}">
            <v-icon v-if="value">mdi-check</v-icon>
          </template>

          <template #item.count="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-on="on" v-bind="attrs" small @click="openSubscriptionsModal(item)">
                  {{ item.count }}
                </v-btn>

              </template>
              <span>{{ $t('subscription.show-subscriptions') }}</span>
            </v-tooltip>
          </template>



        </v-data-table>

      </v-col>
    </v-row>


    <v-dialog max-width="800" v-model="subscriptionListModal" scrollable>
      <v-card>
        <v-card-title>
          {{ $tc('subscription.subscription', 2) }}
        </v-card-title>
        <v-divider class="mb-6"></v-divider>
        <v-card-text>
          <v-data-table
              :headers="subscriptionHeaders"
              :items="subscriptions"
              :loading="subscriptionsLoading"
              :options="{
                itemsPerPage: 10,
              }"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
              }"
              dense
          >
            <template #item.organization.name="{item}">
              <router-link v-if="item.organization" target="_blank" :to="{name: 'organizations.subscriptions.edit', params: {id: item.organization.id, subscriptionId: item.id}}">
                {{ item.organization.name }}
              </router-link>
              <template v-else>-</template>
            </template>
            <template #item.activationStartDate="{value}">
              {{ value | formatDate($t('format_date')) }}
            </template>
            <template #item.billingStartDate="{value}">
              {{ value | formatDate($t('format_date')) }}
            </template>
            <template #item.enabled="{value}">
              <v-icon small v-if="value" color="green">mdi-check</v-icon>
              <v-icon small v-else color="red">mdi-close</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn small @click="subscriptionListModal = false">
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import SubscriptionRepository from "@repository/SubscriptionRepository";

export default {

  data() {
    return {
      loading: false,
      selectedItem: null,
      options: null,
      total: 0,
      subscriptionsLoading: false,
      subscriptionListModal: false,
      subscriptionPlans: [],
      subscriptions: [],
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {

  },

  computed: {
    headers() {
      return [
        {text: this.$t('name'), value: 'name', align: 'left'},
        {text: this.$t('type'), value: 'type'},
        {text: this.$t('enable'), value: 'enabled'},
        {text: this.$t('count'), value: 'count'},
      ]
    },
    subscriptionHeaders() {
      return [
        {text: this.$t('name'), value: 'organization.name', align: 'left'},
        {text: this.$t('subscription.activation-start-date'), value: 'activationStartDate', align: 'left'},
        {text: this.$t('subscription.billing-start-date'), value: 'billingStartDate', align: 'left'},
        {text: this.$t('enable'), value: 'enabled', with: 200},
      ]
    }
  },

  methods: {
    loadData() {

      let data = {}
      data.limit = -1
      this.loading = true
      SubscriptionRepository.searchPlans(data).then(response => {
        this.total = response.total
        this.subscriptionPlans = response.items.map(item => {
          return {
            ...item,
            type: this.$t('enums.subscription-plan-types.' + item.type),
          }
        })
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

    openSubscriptionsModal(item) {
      this.subscriptionListModal = true
      this.loadSubscriptions(item.id)
    },


    loadSubscriptions(id) {
      this.subscriptionsLoading = true
      SubscriptionRepository.search({
        subscriptionPlanId: id,
        limit: -1,
      }).then(response => {
        this.subscriptions = response.items
        this.subscriptionsLoading = false
      }).catch(e => {
        this.notifyError(e)
        this.subscriptionsLoading = false
      })
    }

  },

}
</script>

<style lang="scss">

</style>
